import React from 'react'

function Caption(props) {

    return (

        <div className='flex flex-row gap-4 group items-center justify-center w-11/12 md:w-3/4'>
            <div className='flex w-8 h-8 rounded-full items-center bg-green' style={{backgroundColor: `${props.bg}30`}}>
                <svg className='m-auto animate-bounce' style={{fill: `${props.bg}`}} width="12" height="12" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.57044 15.2122C-0.268162 14.2535 -0.170835 12.7965 0.787832 11.9579L13.371 0.950649C13.6284 0.725433 13.9218 0.56772 14.2296 0.476066C15.0432 0.0881417 16.0448 0.199717 16.7593 0.837669L29.2296 11.9726C30.1798 12.8209 30.2623 14.2788 29.4139 15.2289C28.5655 16.1789 27.1076 16.2614 26.1576 15.4131L15.1151 5.55314L3.82468 15.4296C2.86602 16.2682 1.40904 16.1708 0.57044 15.2122Z" />
                </svg>
            </div>
            <p className='transition duration-500 word-break text-darkgrey text-sm'>{props.text}</p>
        </div>
    )
}

export default Caption