import React from 'react'

function ProjectImage(props) {

    return (

        <img src={props.src} alt={props.alt} className={`${props.style} rounded-lg transition duration-500 shadow-2xl`}></img>
    )
}

export default ProjectImage