import React, { useState } from 'react'
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import pinch1 from '../../assets/images/pinch/Pinch2.png';
import pinch2 from '../../assets/images/pinch/Pinch3.png';
import pinch3 from '../../assets/images/pinch/Pinch5.png';
import pinch4 from '../../assets/images/pinch/Pinch6.png';
import pinch5 from '../../assets/images/pinch/Pinch7.svg';
import pinch6 from '../../assets/images/pinch/Pinch8.svg';
import pinch8 from '../../assets/images/pinch/Pinch10.png';
import Caption from '../../components/Caption';
import PageFlip from '../../components/PageFlip';
import WorkHeader from '../../components/WorkHeader';
import Paragraph from '../../components/Paragraph';
import ProjectImage from '../../components/ProjectImage';

function Pinch() {

    useEffect(() => {
        document.getElementById("works").className = 'transition duration-300 flex flex-col items-center text-lightgrey navbar-links-active'
        window.scrollTo(0, 0);
    }, []);

    const [bg, setBg] = useState('#9C753E');
    const [accent, setAccent] = useState('#75562C');
    const [main, setMain] = useState('#D6A158');

    return (
        <div>
            <div className='flex flex-col w-full overflow-x-hidden items-center bg-[#fff] gap-20'>
                <Helmet>
                    <title>Stephanie Zhang  ⎯  Pinch</title>
                </Helmet>
                <WorkHeader
                    title='Pinch'
                    bg={bg}
                    accent={accent}
                    timeline='2020 - 2022'
                    role='VP of Design'
                    skills='Figma, Adobe Illustrator & Adobe After Effects'
                />
                <div className='flex flex-col gap-2 w-11/12 md:w-3/4 xl:w-1/2'>
                    <p className='text-[#75562C] text-lg mb-2'>Achievements 🏆</p>
                    <div className='flex justify-between'><p>Finalist out of 10,000 applicants chosen for an interview with <a href='https://www.ycombinator.com/' className="transition duration-300 text-lightgrey decoration-1 underline underline-offset-4" target="_blank" rel="noreferrer noopener">YCombinator</a></p><p className='text-darkgrey/70'>S2021 Batch</p></div>
                    <div className='flex justify-between'><p>Finalist at <a href='https://www.youtube.com/watch?v=vMmSW_1SNU4' className="transition duration-300 text-lightgrey decoration-1 underline underline-offset-4" target="_blank" rel="noreferrer noopener">Cooperathon</a> for 'Best Tech Solution' & 'Best Overall Startup'</p><p className='text-darkgrey/70'>November ‘20</p></div>
                    <div className='flex justify-between'><p>Winner at <a href='https://www.utsc.utoronto.ca/research/hub-idea-startup' className="transition duration-300 text-lightgrey decoration-1 underline underline-offset-4" target="_blank" rel="noreferrer noopener">The Hub</a>'s annual Startup competition for seed funding</p><p className='text-darkgrey/70'>November ‘20</p></div>
                </div>
                <div className='flex flex-col lg:flex-row gap-8 select-none items-center'>
                    <ProjectImage src={pinch1} alt='pinch screen 1' style='w-11/12 md:w-80' />
                    <ProjectImage src={pinch2} alt='pinch screen 2' style='w-11/12 md:w-80' />
                    <ProjectImage src={pinch3} alt='pinch screen 3' style='w-11/12 md:w-80' />
                </div>
                <Caption text='A few pages from the customer app' bg={bg} />
                <Paragraph
                    colour={accent}
                    title="What's the problem?"
                    paragraph='Food delivery apps have been on the rise. However, businesses and couriers have taken a hit as the apps take a huge percentage of commission. This has caused businesses to price their food higher on food delivery apps. In addition, businesses are blamed for majority of issues that arise, when they have no control over the service past preparing the food.' />
                <Paragraph
                    colour={accent}
                    title='Research & Competitor analysis'
                    paragraph='I initiated research on existing competitors within the market. Notably, I analyzed popular apps such as Uber Eats, DoorDash, Grubhub, and Skip the Dishes. It became evident that they all operated on a commission-based pricing model and offered similar experiences for users, businesses, and couriers. This observation highlighted the need for us to design an app that differentiates itself by providing unique benefits to businesses and couriers, while still remaining competitive within the consumer base of existing apps.' />
                <ProjectImage src={pinch4} alt='pinch screen 4' style='w-11/12 md:w-3/4 xl:w-1/2' />
                <Paragraph
                    colour={accent}
                    title="Let's build it :-)"
                    paragraph='I started by defining the vibe - I wanted it to be very clean and minimalistic while also having some character. I settled with the colour yellow, as it was a "hungry" colour.' />
                <ProjectImage src={pinch5} alt='pinch screen 5' style='w-11/12 md:w-3/4 xl:w-1/2' />
                <ProjectImage src={pinch6} alt='pinch screen 6' style='w-11/12 md:w-3/4 xl:w-1/2' />
                <Caption text='Hi-Fi Pages from Customer, Business & Courier apps' bg={bg}/>
                <Paragraph
                    colour={accent}
                    title="Reflections"
                    paragraph='Pinch was my first big project that I have done. In addition, we were on a very tight timeline in order to get the initial versions working. This meant that I had to plan my time very carefully, and to make sure that everything I did was efficient. Furthermore, it was also one of my first mobile designs. All in all, Pinch really tested me, and I was able to push myself to adapt to the areas where I lacked experience.' />
                <Paragraph
                    colour={accent}
                    title="What I would have done differently"
                    paragraph='While I did do an immense amount of research in order to tackle my first mobile design, looking back, there are still a lot of UI/UX problems that I would have done differently now. Specifically, focus more on accomodating for edge cases. I believe that there are also a lot of visibility issues that I could have avoided.' />
                <ProjectImage src={pinch8} alt='pinch screen 8' style='w-11/12 md:w-3/4 xl:w-1/2'/>
                <Caption text='Slide deck' bg={bg}/>
                <PageFlip next='Hack the Valley' nextLink='/htv' />
            </div>
        </div>
    );
}

export default Pinch;