import React, { useState } from 'react'
import { useEffect } from 'react';
import branding from '../assets/images/htv/dashboard.png';
import PrimaryButtonLight from './PrimaryButtonLight';
import PrimaryButtonDark from './PrimaryButtonDark';
import Tag from './Tag';

function ProjectCard(props) {

    const [bg, setBg] = useState("[#60877D]");
    const [text, setText] = useState("primary");
    const [title, setTitle] = useState("N/A");
    const [desc, setDesc] = useState("N/A");
    const [current, setCurrent] = useState("current");
    const [CTA, setCTA] = useState("current");

    useEffect(() => {
        setBg(`${props.bg}`);
        setText(`${props.text}`);
        setTitle(props.title);
        setDesc(props.desc);
        setCurrent(props.current);
        setCTA(props.CTA);
    }, []);

    console.log(props.tag);

        return (

            <div className={`flex break-inside-avoid flex-col h-min gap-8 group items-center px-[25px] py-[30px] rounded-[15px] mb-8`} style={{ backgroundColor: `${bg}`}}>
                <img src={props.img} alt="Project Image" className='rounded-[10px]'></img>
                <Tag colour={props.tagColour} tag={props.tag}/>
                <div className='flex flex-col items-center gap-3'>
                    <h2 className={`gellisto text-h3 text-${text}`}>{title}</h2>
                    <p className={`text-tag uppercase text-${text}`}>{current}</p>
                    <p className={`text-${text}`}>{desc}</p>
                </div>
                {CTA === "light"
                    ? <PrimaryButtonLight
                    title='read more'
                    link={props.link}/>
                    : <PrimaryButtonDark
                    title='read more'
                    link={props.link} />
                }
            </div>
        )
    }

    export default ProjectCard