import React from 'react'
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useRef } from "react";
import '../index.css';
import pdf from '../assets/StephanieZhang-Resume.pdf';


function NavBar() {

    const navRef = useRef();
    const [open, setOpen] = useState(false);

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
        setOpen(!open);
    };

    return (
        <header className='flex w-screen flex-col items-center'>
            <div className='fixed w-10/12 flex flex-row z-50 py-8 justify-between'>
                <NavLink to="/"><p className='gellisto text-lg text-secondary'>stephanie zhang</p></NavLink>
                <button className="group" onClick={showNavbar}>
                    <svg width="100" height="26" viewBox="0 0 100 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect className={`transition duration-300 ${(open ? 'rotate-14' : '')}`} width="100" height="2" fill="#F0E7E4" />
                        <rect className={`transition duration-300 origin-center ${(open ? 'rotate-14' : '')}`} y="12" width="100" height="2" fill="#F0E7E4" />
                        <rect className={`transition duration-300 origin-bottom-left ${(open ? 'rotate-15' : '')}`} y="24" width="100" height="2" fill="#F0E7E4" />
                    </svg>
                </button>
            </div>
            <nav ref={navRef} className='z-30 bg-primary w-screen items-center w-screen'>
                <div className="fixed h-screen flex flex-col items-center z-40 bg-primary py-8">
                    <div className="flex flex-col h-full w-screen bg-primary items-center justify-center gap-16 md:gap-24 font-medium text-lightclay text-[3rem] md:text-nav gellisto select-none py-10 ">
                        <NavLink to="/" id="works" onClick={showNavbar} className={({ isActive }) => ("transition duration-300 hover:text-secondary hover:line-through decoration-6 ") + (isActive ? 'text-secondary line-through' : '')}>works</NavLink>
                        <NavLink to="/about" onClick={showNavbar} className={({ isActive }) => ("transition duration-300 hover:text-secondary hover:line-through decoration-6 ") + (isActive ? 'text-secondary line-through' : '')}>about</NavLink>
                        <a href={pdf} target="_blank" type='application/pdf' onClick={showNavbar} className="transition duration-300 hover:text-secondary hover:line-through" rel="noreferrer noopener">resume</a>
                    </div>
                    <div className='flex flex-row items-center gap-6'>
                        <a href='mailto:stephaniey.zhang@mail.utoronto.ca' target="_blank" rel="noreferrer noopener">
                            <svg className="transition duration-300 fill-lightclay hover:fill-secondary" width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.48443 10.7959C4.48443 9.15393 5.64706 8.04561 7.97232 7.86089L10.609 7.65564V7.51197C10.609 6.58837 10.0484 6.11631 9.01038 6.11631C7.78547 6.11631 7.12111 6.56785 7.12111 7.40935H4.7128C4.7128 5.31585 6.45675 3.94071 9.15571 3.94071C11.8962 3.94071 13.4325 5.439 13.4325 8.10718V10.8164C13.4325 11.5758 13.7232 11.9247 14.346 11.9247C15.301 11.9247 15.9654 10.488 15.9654 8.374C15.9654 4.57697 13.1211 1.88825 9.11419 1.88825C5.00346 1.88825 2.0346 4.88484 2.0346 8.98974C2.0346 13.1152 5.02422 16.1117 9.11419 16.1117C10.4844 16.1117 11.8962 15.6602 13.1834 14.8392L14.0346 16.5838C12.5813 17.5074 10.8789 18 9.11419 18C3.86159 18 0 14.1824 0 8.98974C0 3.81756 3.88235 0 9.11419 0C14.2422 0 18 3.55074 18 8.374C18 11.7195 16.4221 13.7719 13.8685 13.7719C12.436 13.7719 11.4602 13.1973 11.0865 12.1095C10.7543 13.0536 9.38408 13.7719 7.9308 13.7719C5.77163 13.7719 4.48443 12.6636 4.48443 10.7959ZM7.43253 10.6727C7.43253 11.3501 7.84775 11.6579 8.74049 11.6579C9.92388 11.6579 10.6298 11.0627 10.6298 10.057V9.56442L9.15571 9.68757C7.84775 9.79019 7.43253 10.0775 7.43253 10.6727Z" />
                            </svg>
                        </a>
                        <a href='https://www.linkedin.com/in/stephaniezhang25/' target="_blank" rel="noreferrer noopener">
                            <svg className="transition duration-300 fill-lightclay hover:fill-secondary" width="20" height="20" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.280053 5.64457H3.81042V16.9999H0.280053V5.64457ZM2.04533 0C3.17368 0 4.09066 0.916983 4.09066 2.04722C4.09066 3.17633 3.17368 4.09331 2.04533 4.09331C0.914334 4.09331 0 3.17633 0 2.04722C0 0.916983 0.914334 0 2.04533 0Z" />
                                <path d="M6.02441 5.64462H9.40964V7.1974H9.4579C9.92907 6.30426 11.0807 5.36343 12.7971 5.36343C16.371 5.36343 17.0307 7.71455 17.0307 10.772V17H13.503V11.4779C13.503 10.1607 13.4801 8.46691 11.669 8.46691C9.83237 8.46691 9.55213 9.90256 9.55213 11.3832V17H6.02441V5.64462Z" />
                            </svg>
                        </a>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default NavBar