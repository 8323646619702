import React from 'react';
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import PageFlip from '../../components/PageFlip';
import snkrsalarm from '../../assets/images/dev/snkrsalarm.mp4';
import ameu from '../../assets/images/dev/ameu.mp4';
import amasia from '../../assets/images/dev/amasia.mp4';

function Frontend() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <div className='flex flex-col w-full overflow-x-hidden items-center'>
                <Helmet>
                    <title>Stephanie Zhang  ⎯  Frontend</title>
                </Helmet>
                <PageFlip next='Stegvision' nextLink='/stegvision' />
                <div className='flex flex-col gap-12 items-center my-12'>
                    <p className='blisstwin text-titlesm sm:text-title select-none'>front-end developing</p>
                    <div className='flex flex-col lg:flex-row gap-8'>
                        <div className='flex flex-col w-64'>
                            <p className='text-green'>timeline</p>
                            <p className=''>Sept. 2021 - Present</p>
                        </div>
                        <div className='flex flex-col w-64'>
                            <p className='text-green'>projects</p>
                            <p className=''>Websites & Desktop apps</p>
                        </div>
                        <div className='flex flex-col w-64'>
                            <p className='text-green'>languages</p>
                            <p className=''>HTML/CSS, Tailwind CSS, Javascript, React, Electron, Python, C, Java</p>
                        </div>
                    </div>
                    <div className='w-full h-0.5 bg-darkgrey/40'></div>
                    <div className='flex flex-col gap-4 w-3/4 xl:w-1/2'>
                        <p className='text-green text-lg'>snkrs alarm chrome extention</p>
                        <p>This was my first time working with chrome extensions, as well as one of my first projects in general. The view was developed entirely with vanilla HTML & CSS. The UI was also designed by me.</p>
                        <video src={snkrsalarm} alt='inspiration' className='img-portfolio' controls muted></video>
                    </div>
                    <div className='flex flex-col gap-4 w-3/4 xl:w-1/2'>
                        <p className='text-green text-lg'>AMNotify ATC webpage</p>
                        <p>The views were developed entirely with vanilla HTML & CSS. The webpages are also designed by me including the graphics.</p>
                        <video src={ameu} alt='inspiration' className='img-portfolio' controls muted></video>
                        <video src={amasia} alt='inspiration' className='img-portfolio' controls muted></video>
                    </div>
                    <PageFlip next='Stegvision' nextLink='/stegvision' />
                </div>
            </div>
        </div>
    );
}

export default Frontend;