import React, { useState } from 'react'
import { useEffect } from 'react';
import PrimaryButton from './PrimaryButtonLight';
import TagSolid from './TagSolid';

function ExperienceCard(props) {

    const [bg, setBg] = useState("[#60877D]");
    const [text, setText] = useState("secondary");
    const [button, setButton] = useState("border-secondary");
    const [title, setTitle] = useState("N/A");
    const [desc, setDesc] = useState("N/A");
    const [tag, setTag] = useState("experience");
    const [current, setCurrent] = useState("current");

    useEffect(() => {
        setBg(`${props.bg}`);
        setText(`${props.text}`);
        setButton(`border-${props.text}`);
        setTitle(props.title);
        setDesc(props.desc);
        setTag(props.tag);
        setCurrent(props.current);
    }, []);

    return (
        <div className={`flex break-inside-avoid flex-col h-min gap-8 group items-center px-[25px] py-[30px] rounded-[15px] mb-8`} style={{ backgroundColor: `${bg}` }}>
            <TagSolid
                tag={props.tag}
                colour={props.tagColour} />
            <div className='flex flex-col items-center gap-3'>
                <h2 className={`gellisto text-h3 text-${text}`}>{title}</h2>
                <p className={`text-tag uppercase text-${text}`}>{current}</p>
                <p className={`text-${text}`}>{desc}</p>
            </div>
            <PrimaryButton
                text={props.text}
                button={props.text}
                title='see more'
                link={props.link}
                bg={props.bg} />
        </div>
    )
}

export default ExperienceCard