import React from 'react'

function Warning(props) {

    return (

        <div className='flex flex-col gap-8 w-11/12 md:w-3/4 xl:w-1/2 justify-center items-center py-12 px-12 rounded-md' style={{backgroundColor: `${props.bg}`}}>
            <p className='text-white text-[24px] text-center'>{props.title}</p>
            <p className='text-white text-center'>{props.desc}</p>
            <a href={props.link} className='bg-green rounded-md text-primary text-center text-sm px-4 py-2 font-bold' rel="noreferrer" style={{backgroundColor: `${props.buttoncolour}`}}><button>{props.button}</button></a>
        </div>
    )
}

export default Warning