import React, { useState } from 'react'
import ExperienceCard from '../components/ExperienceCard';
import ProjectCard from '../components/ProjectCard';


function Dev() {

    return (
        <div className='gap-8 columns-1 md:columns-2 lg:columns-3'>
           <ExperienceCard
                bg='#6A7048'
                title='AMNotify'
                desc="AMNotify Canada is the industry's leading community of collectors and resellers alike. We offer the highest-quality information for Sneakers, Collectibles, NFTs, Funko, and so much more! "
                tag='FREELANCE PROJECT'
                text='secondary'
                tagColour='#CBFB8F'
                link='amnotify' />
        </div>
    );
}

export default Dev;