import React, { useState } from 'react'
import ExperienceCard from '../components/ExperienceCard';
import ProjectCard from '../components/ProjectCard';
import pinch from '../assets/images/pinch/Pinch8.svg';
import inform from '../assets/images/inform/inform.png';
import arc from '../assets/images/arc/Arc.svg';
import steg from '../assets/images/stegvision/Stegvision.png';
import htv from '../assets/images/htv/htv.svg';


function Design() {

    return (
        <div className='gap-8 columns-1 md:columns-2 lg:columns-3'>
            <ExperienceCard
                bg='#60877D'
                title='Reshift Media'
                desc='A digital marketing company specializing in social media, search, software, and website design/development.'
                tag='experience'
                text='secondary'
                tagColour='#CBFB8F'
                current='current'
                link='reshift' />
            <ProjectCard
                bg='#615A56'
                text='secondary'
                current='current'
                title='Inform Reviews'
                tagColour='#8baafb'
                img={inform}
                CTA='light'
                link='inform'
                desc='A hands-free review management tool that replies to weekly reviews and provides weekly insights using our automated assistant.'
                tag='startup project' />
            <ExperienceCard
                bg='#606887'
                title='ICUBE UTM'
                text='secondary'
                tag='work study'
                tagColour='#B79DFF'
                link='icube'
                desc='ICUBE is the home of Social Entrepreneurship and early-stage start-ups at University of Toronto, Mississauga.' />
            <ProjectCard
                bg='#C7BEBA'
                title='Pinch'
                desc='Pinch’s mission is to provide local couriers and restaurants to keep their orders in their own hands.'
                tag='startup project'
                img={pinch}
                tagColour='#4767cd'
                link='pinch' />
            <ExperienceCard
                bg='#876075'
                title='Freelance'
                tag='FREELANCE'
                text='secondary'
                tagColour='#AACBFD'
                link='freelance'
                desc='Freelancing is a where individuals offer services on a contractual basis to clients or businesses, typically on a project-by-project basis.' />
            <ProjectCard
                bg='#C7BEBA'
                title='Hack the Valley'
                tag='experience'
                tagColour='#d07000'
                link='htv'
                img={htv}
                desc='750 innovative and creative developers, designers, and creators for 36 hours of hacking. A hackathon that has been ongoing for seven years now. Hosted by University of Toronto.' />
            <ProjectCard
                bg='#F0E7E4'
                title='Arc AIO'
                tag='project'
                img={arc}
                tagColour='#378115'
                link='arc'
                desc='Arc AIO is an All-In-One collectibles and sneaker bot, supporting an expansive site list. Arc AIO gives users the advantage of copping the latest items in Canada.' />
            <ProjectCard
                bg='#615A57'
                text='secondary'
                title='StegVision'
                link='stegvision'
                tag='freelance project'
                tagColour='#fb8bba'
                CTA='light'
                img={steg}
                desc='StegVision offers consumer level brand protection and fraud resolution that solves multi-billion dollar problems across industry.' />
        </div>
    );
}

export default Design;