import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

function PrimaryButtonDark(props) {

    const [title, setTitle] = useState("N/A");
    const [link, setLink] = useState("link");

    useEffect(() => {
        setTitle(props.title);
        setLink(props.link);
    }, []);

    return (

        <NavLink to={`/${link}`} className={`rounded-full justify-center items-center border border-primary border-solid py-4 px-8 text-button text-primary hover:bg-primary hover:border-primary hover:text-secondary transition ease-in duration-200`}>
            {title}
        </NavLink>
    )
}

export default PrimaryButtonDark