import React, { useState } from 'react'
import { useEffect } from 'react';

function TagSolid(props) {

    const [colour, setColour] = useState("N/A");
    const [tag, setTag] = useState("link");

    useEffect(() => {
        setColour(props.colour);
        setTag(props.tag);
    }, []);

    return (

        <div className={`rounded-[5px] py-2.5 px-4 text-bold uppercase text-tag text-primary`} style={{backgroundColor: `${colour}`}}>{tag}</div>
    )
}

export default TagSolid