import React, { useState } from 'react'
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import PageFlip from '../../components/PageFlip';
import WorkHeader from '../../components/WorkHeader';
import Paragraph from '../../components/Paragraph';


function Reshift() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [bg, setBg] = useState('#4E5253');
    const [accent, setAccent] = useState('#537D6D');
    const [main, setMain] = useState('#D6A158');

    return (
        <div className='bg-[#fff]'>
            <div className='flex flex-col w-full overflow-x-hidden items-center gap-20'>
                <Helmet>
                    <title>Stephanie Zhang  ⎯  ICUBE UTM</title>
                </Helmet>
                <WorkHeader
                title='ICUBE UTM'
                bg={bg}
                accent={accent}
                timeline='Oct. 2022 - Feb. 2023'
                role='Lead Designer'
                skills='Figma, Bubble.io, Adobe Illustrator'/>
                <div className='flex flex-col gap-12 items-center'>
                    <Paragraph
                    colour={accent}
                    title='What did I do?'
                    paragraph="I was a graphic designer at ICUBE UTM during my fall/winter 2022/2023 school year at U of T.

                    I worked mainly on the UI/UX design of a website/dashboard of a startup, however I also aided in the development process by helping create an initial prototype using bubble.io. Near the end of my term, I helped a different startup develop their brand identity as well as redesigned their logo."/>
                    <PageFlip next='Front-End' nextLink='/frontend' />
                </div>
            </div>
        </div>
    );
}

export default Reshift;