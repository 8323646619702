import React, { useState } from 'react'
import { useEffect } from 'react';

function Tag(props) {

    const [colour, setColour] = useState("N/A");
    const [bg, setBg] = useState("N/A");
    const [tag, setTag] = useState("link");

    useEffect(() => {
        setColour(props.colour);
        setTag(props.tag);
        setBg(`${props.colour}25`);
    }, []);

    console.log({bg});

    return (

        <div className={`rounded-[5px] py-2.5 px-4 text-bold uppercase text-tag`} style={{color: `${colour}`, backgroundColor: `${bg}`}}>{tag}</div>
    )
}

export default Tag