import React, { useState } from 'react'
import { useEffect } from 'react';
import PageFlip from '../components/PageFlip';

function WorkHeader(props) {

    const [faded, setFaded] = useState("N/A");
    const [tag, setTag] = useState("link");
    const [text, setText] = useState("link");

    useEffect(() => {
        setFaded(`${props.bg}10`);
        setTag(props.tag);
    }, []);

    console.log(faded);

    return (

        <div className='flex flex-col gap-12 items-center text-secondary'>
            <div className='flex flex-col pt-28 pb-16 w-screen items-center justify-center gap-24' style={{ backgroundColor: `${props.bg}` }}>
                <PageFlip next='Front-End' nextLink='/frontend' />
                <p className='gellisto text-[3rem] sm:text-title select-none text-white'>{props.title}</p>
            </div>
            <div className='flex flex-col lg:flex-row gap-8 text-primary rounded-md py-8 px-20 items-center justify-center' style={{backgroundColor: `${faded}`}}>
                <div className='flex flex-col w-64 items-center justify-center'>
                    <p className='gellisto' style={{ color: `${props.accent}` }}>Timeline</p>
                    <p className='text-center' style={{ color: `${props.text}` }}>{props.timeline}</p>
                </div>
                <div className='flex flex-col w-64 items-center justify-center'>
                    <p className='gellisto' style={{ color: `${props.accent}` }}>Role</p>
                    <p className='text-center' style={{ color: `${props.text}` }}>{props.role}</p>
                </div>
                <div className='flex flex-col w-64 items-center justify-center'>
                    <p className='gellisto' style={{ color: `${props.accent}` }}>Skills</p>
                    <p className='text-center' style={{ color: `${props.text}` }}>{props.skills}</p>
                </div>
            </div>
        </div>
    )
}

export default WorkHeader