import React from 'react'
import { NavLink } from 'react-router-dom';

function PageFlip() {

    return (

        <div className='flex w-11/12 md:w-3/5 justify-between select-none group'>
            <NavLink to="/" className='flex flex-row gap-3 text-white group items-center group-hover:text-secondary transition duration-500'>
                <svg className='fill-white transition duration-500 group-hover:fill-secondary' width="20" height="14" viewBox="0 0 23 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.83258 11.6652C8.31322 12.1458 8.31322 12.9251 7.83258 13.4057C7.35193 13.8864 6.57265 13.8864 6.09201 13.4057L0.870295 8.18402C0.639482 7.9532 0.509812 7.64015 0.509812 7.31373C0.509813 6.98731 0.639482 6.67426 0.870297 6.44345L6.09201 1.22174C6.57266 0.741096 7.35194 0.741096 7.83258 1.22174C8.31323 1.70239 8.31323 2.48167 7.83258 2.96231L4.71192 6.08296L20.8869 6.08296C21.5666 6.08296 22.1176 6.634 22.1176 7.31373C22.1176 7.99347 21.5666 8.5445 20.8869 8.5445L4.71192 8.5445L7.83258 11.6652Z" />
                </svg>
                back to works
            </NavLink>
        </div>
    )
}

export default PageFlip