import React from 'react';
import { useEffect } from 'react';
import { useState } from "react"
import Helmet from 'react-helmet';
import PageFlip from '../../components/PageFlip';
import HTVnew from '../../assets/images/htv/HTVnew.png';
import HTVold from '../../assets/images/htv/HTVold.png';
import dashboard from '../../assets/images/htv/dashboard.png';
import htv5promo from '../../assets/images/htv/5promo.png';
import htv7promo from '../../assets/images/htv/7promo.png';
import packages from '../../assets/images/htv/packages.png';
import tags from '../../assets/images/htv/tags.png';
import stickers from '../../assets/images/htv/stickers.png';
import room from '../../assets/images/htv/room.png';
import merch from '../../assets/images/htv/merch.png';
import Caption from '../../components/Caption';
import WorkHeader from '../../components/WorkHeader';
import Paragraph from '../../components/Paragraph';
import ProjectImage from '../../components/ProjectImage';

function HTV() {
    const [isChecked, setIsChecked] = useState(true);
    const handleChange = () => {
        setIsChecked(!isChecked);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [bg, setBg] = useState('#208346');
    const [accent, setAccent] = useState('#21B459');
    const [main, setMain] = useState('#86CD74');

    return (
        <div className='bg-[#272532] pb-20'>
            <div className='flex flex-col w-full overflow-x-hidden items-center gap-20 text-secondary'>
                <Helmet>
                    <title>Stephanie Zhang  ⎯  Hack the Valley</title>
                </Helmet>
                <WorkHeader
                    title='Hack the Valley'
                    bg={bg}
                    accent={main}
                    timeline='2020 - 2022'
                    role='Lead Designer'
                    skills='Visual Design' 
                    text='#fff'/>
                <div className='flex flex-col gap-12 items-center'>
                    <Paragraph
                        colour={main}
                        title='Goal'
                        paragraph="Looking at Hack the Valley's previous branding, it was unattractive and unidentifyable. I was tasked to create a new logo and brand identity, with free creative reign. My ultimate goal was to create a logo & identity that can be easily recognized as ~HTV~ while keeping it simple and minimalistic." />
                    <Paragraph
                        colour={main}
                        title='Inspiration'
                        paragraph="It was important to me that the brand and identity had a playful vibe, in order to not look intimidating to newcomers. Therefore, I wanted it to have more connection with the name and origin of Hack the Valley, while keeping the 'tech-y' look." />

                    <label class="relative inline-flex items-center cursor-pointer gap-5 select-none">
                        <span class="text-sm font-medium text-green">Old</span>
                        <input type="checkbox" defaultChecked={isChecked} value="" onChange={handleChange} class="sr-only peer" />
                        <div class="w-12 h-6 peer-focus:outline-green bg-green/20 transition rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-lightgrey peer-checked:ring-4 peer-checked:ring-green/30 after:content-[''] after:absolute after:bg-white after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-green"></div>
                        <span class="text-sm font-bold text-green">New</span>
                    </label>
                    <div className='flex flex-col w-11/12 md:w-3/4 xl:w-1/2 select-none'>
                        {isChecked ? <img src={HTVnew} alt="New HTV"></img> : <img src={HTVold} alt="Old HTV"></img>}
                    </div>
                    <Caption text='New vs. Old website and branding' bg={accent} />
                    <ProjectImage src={dashboard} alt='HTV Dashboard' style='w-11/12 md:w-3/4 xl:w-1/2' />
                    <Caption text='Dashboard UI' bg={accent} />
                    <Paragraph
                        colour={main}
                        title='Thoughts & Reflections'
                        paragraph="Working on this project with complete free creative reign really allowed me to work on my ability to build on ideas. Sometimes it can be difficult to begin when you have no initial direction of what the branding or identity would look like. <br></br><br></br>In addition, with the limited amount of time, I had to prioritize parts of the project that I could deliver off to the other teams, as many teams were unable to start and were dependent on me." />
                    <Paragraph
                        colour={main}
                        title='Marketing, Swag & Miscellaneous'
                        paragraph="While Hack the Valley 5 was online, Hack the Valley 7 was in person in October '22. I was tasked with creating stickers, packages, nametags, room signs and lastly, merch for both executives and hackers." />
                    <div className='flex flex-col md:flex-row gap-4 w-11/12 md:w-3/4 xl:w-1/2'>
                        <ProjectImage src={htv5promo} alt='HTV5 Promotional Posts' style='md:w-1/2 h-1/2' />
                        <ProjectImage src={htv7promo} alt='HTV7 Promotional Posts' style='md:w-1/2 h-1/2' />
                    </div>
                    <Caption text='Promotional posts for Social Media' bg={accent} />
                    <ProjectImage src={packages} alt='HTV Dashboard' style='w-11/12 md:w-1/2 h-1/2' />
                    <Caption text='Sponsor & Hacker Packages for Information' bg={accent} />
                    <div className='flex flex-col md:flex-row gap-4 w-11/12 md:w-3/4 xl:w-1/2'>
                        <ProjectImage src={tags} alt='HTV5 Identity Tags' style='md:w-1/2 h-1/2' />
                        <ProjectImage src={stickers} alt='HTV7 Stickers' style='md:w-1/2 h-1/2' />
                    </div>
                    <Caption text='Name Tags & Stickers for in-person event' bg={accent} />
                    <ProjectImage src={room} alt='HTV Room Signs' style='w-11/12 md:w-1/2 h-1/2' />
                    <ProjectImage src={merch} alt='HTV Merch' style='w-11/12 md:w-1/2 h-1/2' />
                    <Caption text='Merch handed out to executives, volunteers and hackers' bg={accent} />
                    <PageFlip next='Reshift Media' nextLink='/reshift' />
                </div>
            </div>
        </div>
    );
}

export default HTV;