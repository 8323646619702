import React, { useState } from 'react'
import Helmet from 'react-helmet';
import { NavLink } from 'react-router-dom';
import "../index.css";
import { useRef } from 'react';
import Design from './Design';
import Dev from './Dev';

function Home() {

    const ref = useRef(null);
    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const [isChecked, setIsChecked] = useState(false)

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }

    return (
        <div className='flex flex-col w-full h-full py-24 items-center justify-center z-10 bg-primary'>
            <Helmet>
                <title>Stephanie Zhang  ⎯  Portfolio</title>
                <meta name="description" content="designer based in vancouver, ca" data-react-helmet="true" />
            </Helmet>
            <div className='flex flex-col justify-between items-center rounded-[15px] w-10/12 w-full pt-16 md:py-36 md:pb-20 gap-20'>
                <div className='flex flex-col justify-between items-center gap-12 mx-6'>
                    <h1 className='gellisto text-h1 text-secondary z-10'>stephanie zhang</h1>
                    <p className='text-body text-secondary opacity-75 z-10'>is a designer & developer based in Vancouver, CA</p>
                </div>
                <div className='flex flex-row justify-between items-center gap-6 z-10'>
                    <button onClick={handleClick} className='flex flex-row items-center justify-center rounded-full bg-accent py-4 px-8 gap-2 text-primary font-semibold text-button'>view work
                        <svg width="11" height="13" viewBox="0 0 11 13" className='fill-primary' xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.016 12.5045L0.498667 8.04484L6.23683e-07 7.54933L0.997333 6.5583L1.496 7.05381L4.81067 10.3184L4.81067 0.699551L4.81067 -2.70544e-07L6.21867 -2.08999e-07L6.21867 0.699551L6.21867 10.3184L9.504 7.05381L10.0027 6.5583L11 7.54933L10.5013 8.04484L6.01333 12.5045L5.51467 13L5.016 12.5045Z" />
                        </svg>
                    </button>
                    <NavLink to="/about" className='rounded-full justify-center items-center border border-secondary border-solid py-4 px-8 text-button text-secondary hover:bg-secondary hover:border-secondary hover:text-primary transition ease-in duration-200'>
                        about me
                    </NavLink>
                </div>
            </div>
            <div ref={ref} className='flex flex-col items-center w-11/12 md:w-10/12 gap-24 pt-40'>
                <label className='w-min relative inline-flex cursor-pointer select-none items-center justify-center rounded-full bg-secondary p-1'>
                    <input type='checkbox' className='sr-only' checked={isChecked} onChange={handleCheckboxChange} />
                    <span className={`flex slider duration-200 items-center space-x-[6px] rounded-full py-4 px-8 text-button font-medium ${!isChecked ? 'text-secondary bg-primary' : ''}`}>
                        design
                    </span>
                    <span
                    ></span>
                    <span
                        className={`flex slider duration-200 items-center space-x-[6px] rounded-full py-4 px-8 text-button font-medium ${isChecked ? 'text-secondary bg-primary' : ''}`}>
                        develop
                    </span>
                </label>
                {isChecked ? <Dev/> : <Design />}
            </div>
        </div>
    );
}

export default Home;