import React from 'react'

function Paragraph(props) {

    return (

        <div className='flex flex-col gap-4 w-11/12 md:w-3/4 xl:w-1/3'>
            <p className='text-lg' style={{ color: `${props.colour}` }}>{props.title}</p>
            <p>{props.paragraph}</p>
        </div>
    )
}

export default Paragraph