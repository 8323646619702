import NavBar from './components/NavBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import { Helmet } from 'react-helmet';
import Home from './pages/Home';
import Footer from './components/Footer';
import ScrollToTop from "react-scroll-up";
import About from './pages/About';
import Pinch from './pages/work/Pinch';
import HTV from './pages/work/HTV';
import Icube from './pages/work/Icube';
import Arc from './pages/work/Arc';
import Frontend from './pages/work/Frontend';
import Stegvision from './pages/work/Stegvision';
import Reshift from './pages/work/Reshift';
import Inform from './pages/work/Inform';
import AMNotify from './pages/work/AMNotify';
import Freelance from './pages/work/Freelance';

function App() {
  return (
    <>
      <div className='w-screen flex flex-col items-center justify-center overflow-x-hidden'>
        <div className='overflow-x-hidden justify-center items-center place-content-center w-screen'>
          <Helmet>
            <title>Stephanie Zhang ⎯ Portfolio</title>
            <meta name="description" content="From Helmet" />
          </Helmet>
          <Router>
            <ScrollToTop showUnder={160} style={{ bottom: 35, right: 35, zIndex: 1000 }}>
              <div className='flex w-12 h-12 rounded-md bg-secondary items-center'>
                <svg className='m-auto fill-primary' width="20" height="12" viewBox="0 0 30 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.57044 15.2122C-0.268162 14.2535 -0.170835 12.7965 0.787832 11.9579L13.371 0.950649C13.6284 0.725433 13.9218 0.56772 14.2296 0.476066C15.0432 0.0881417 16.0448 0.199717 16.7593 0.837669L29.2296 11.9726C30.1798 12.8209 30.2623 14.2788 29.4139 15.2289C28.5655 16.1789 27.1076 16.2614 26.1576 15.4131L15.1151 5.55314L3.82468 15.4296C2.86602 16.2682 1.40904 16.1708 0.57044 15.2122Z" />
                </svg>
              </div>
            </ScrollToTop>
            <span className='z-50'><NavBar /></span>
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/about' element={<About />} />
              <Route exact path='/pinch' element={<Pinch />} />
              <Route exact path='/htv' element={<HTV />} />
              <Route exact path='/icube' element={<Icube />} />
              <Route exact path='/arc' element={<Arc />} />
              <Route exact path='/frontend' element={<Frontend />} />
              <Route exact path='/stegvision' element={<Stegvision />} />
              <Route exact path='/reshift' element={<Reshift />} />
              <Route exact path='/inform' element={<Inform />} />
              <Route exact path='/amnotify' element={<AMNotify />} />
              <Route exact path='/freelance' element={<Freelance />} />
            </Routes>
            <Footer />
          </Router>
        </div>
      </div>
    </>
  );
}

export default App;
