import React from 'react'
import Helmet from 'react-helmet';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import Portrait from '../assets/images/Self.png'

function About() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className='flex flex-col w-full h-full pt-24 md:py-24 items-center z-10'>
                <Helmet>
                    <title>Stephanie Zhang  ⎯  About</title>
                    <meta name="description" content="designer based in vancouver, ca" data-react-helmet="true" />
                </Helmet>
                <div className='flex flex-col items-center gap-12 mt-12'>
                    <div className='w-10/12'><p className='text-[3rem] md:text-h1 text-secondary gellisto select-none'>about me</p></div>
                    <div className='flex flex-col gap-12 md:mt-8'>
                        <div className='flex flex-col md:flex-row md:gap-24'>
                            <img className='w-11/12 md:w-1/3 rounded-tr-[5px] rounded-br-[5px] select-none' src={Portrait}></img>
                            <div className='flex flex-col gap-4 my-10 items-center'>
                                <p className='text-[16px] w-11/12 md:w-10/12 text-secondary'>
                                    I'm a Designer and Developer based in Vancouver, Canada.
                                    <br></br><br></br>
                                    Before entering the tech world, I was a traditional artist.
                                    I studied Economics, Statistics and Visual Studies at University of Toronto. 
                                    <br></br><br></br>
                                    Apart from design, I enjoy playing competitive Call of Duty 🎮, listening to music 🎵 and  <a rel="noreferrer noopener" target="_blank" href='https://drive.google.com/drive/folders/1KWwyRb0VZ6WPYxe9XzoSnlpm1y6-qt13?usp=sharing' className="transition duration-300 text-lightgrey hover:text-accent decoration-1 underline underline-offset-4">drawing</a> ✏️ :-)
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default About