import React, { useState } from 'react'
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import PageFlip from '../../components/PageFlip';
import WorkHeader from '../../components/WorkHeader';
import Paragraph from '../../components/Paragraph';


function Reshift() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [bg, setBg] = useState('#020A1C');
    const [accent, setAccent] = useState('#1A8ECD');
    const [main, setMain] = useState('#D6A158');

    return (
        <div className='bg-[#fff]'>
            <div className='flex flex-col w-full overflow-x-hidden items-center gap-20'>
                <Helmet>
                    <title>Stephanie Zhang  ⎯  Reshift Media</title>
                </Helmet>
                <WorkHeader
                title='Reshift Media'
                bg={bg}
                accent={accent}
                timeline='July 2023 to Present'
                role='UI/UX Designer'
                skills='Figma'/>
                <div className='flex flex-col gap-12 items-center'>
                    <Paragraph
                    colour={accent}
                    title='What am I doing?'
                    paragraph="In this role, I utilize my proficiency in product, user experience, and visual design to meet client demands. I've conducted user research and usability testing, using data to create user-focused solutions. I keep an effective communication and collaboration with cross-functional teams in order to drive a cohesive work environment, especially in an online setting. I am also managing multiple projects and prioritizing tasks to consistently meet deadlines well in advance of their scheduled due dates. Notably, I specialize in customizing solutions for franchises and B2B clients, ensuring alignment with their unique requirements."/>
                    <PageFlip next='Front-End' nextLink='/frontend' />
                </div>
            </div>
        </div>
    );
}

export default Reshift;