import React from 'react';
import { useEffect } from 'react';
import { useState } from "react"
import Helmet from 'react-helmet';
import PageFlip from '../../components/PageFlip';
import Caption from '../../components/Caption';
import desktop from '../../assets/images/arc/desktop.png';
import hmw from '../../assets/images/arc/HMW.png';
import ui from '../../assets/images/arc/UI.png';
import profiles from '../../assets/images/arc/profiles.png';
import tasks from '../../assets/images/arc/tasks.png';
import branding from '../../assets/images/arc/branding.png';
import promo from '../../assets/images/arc/promo.png';
import WorkHeader from '../../components/WorkHeader';
import Paragraph from '../../components/Paragraph';
import ProjectImage from '../../components/ProjectImage';

function HTV() {

    useEffect(() => {
        document.getElementById("works").className = 'transition duration-300 flex flex-col items-center text-lightgrey navbar-links-active'
        window.scrollTo(0, 0);
    }, []);

    const [bg, setBg] = useState('#2A5DAA');
    const [accent, setAccent] = useState('#21B459');
    const [main, setMain] = useState('#B3D1E9');

    return (
        <div className='bg-[#0F1327] pb-20'>
            <div className='flex flex-col w-full overflow-x-hidden items-center gap-20 text-secondary'>
                <Helmet>
                    <title>Stephanie Zhang  ⎯  Arc AIO</title>
                </Helmet>
                <WorkHeader
                    title='Arc AIO'
                    bg={bg}
                    accent={main}
                    timeline='2021 - 2022'
                    role='Lead Designer'
                    skills='Product Design, UI/UX, Web Design, Testing, Visual Design'
                    text='#fff' />
                <div className='flex flex-col gap-4 w-11/12 md:w-3/4 xl:w-1/2'>
                    <img src={desktop} alt="Arc Dashboard" className='rounded-lg'></img>
                </div>
                <Caption text='Dashboard UI' bg={main} />
                <Paragraph
                    colour={main}
                    title='Talking to users'
                    paragraph="We set our targeted user group to be individuals with an interest in sneakers or other highly sought after items. The questions are based on the individuals experience with current sneaker bots on the market, in order to understand their habits & purposes." />
                <div className='flex flex-col w-11/12 md:w-1/3 gap-4'>
                    <p className='text-darkgrey'>We have found one commom issue with all individuals...</p>
                    <p className='text-lg'>"Botting at high volumes is difficult to manage."</p>
                </div>
                <Paragraph
                    colour={main}
                    title='How do we change this?'
                    paragraph="I started off with generating some potential features that we may include. By asking how might we's, we can determine the issue and solutions based on the users' needs." />
                <div className='flex flex-col gap-4 w-11/12 md:w-3/4 xl:w-1/2 items-center'>
                    <img src={hmw} alt="Arc HMW" className=' w-11/12 md:w-3/4 rounded-lg'></img>
                    <Caption text="How might we's" bg={main} />
                </div>
                <p className='text-[#B3D1E9] text-lg'>Introducing Arc AIO</p>
                <div className='flex flex-col md:flex-row items-center gap-12 w-11/12 md:w-3/4 xl:w-1/2'>
                    <img src={tasks} alt="Arc Tasks" className='w-11/12 md:w-3/4 xl:w-2/3'></img>
                    <div className='flex flex-col gap-8 self-center'>
                        <div className='flex flex-col gap-4'>
                            <p className='text-[#B3D1E9] text-md self-start'>Task groups</p>
                            <p>Organization for tasks & proxies to be able to bot at high volumes easily.</p>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <p className='text-[#B3D1E9] text-md self-start'>Mass edit mode</p>
                            <p>Edit all button to mass edit keywords/ID, profiles or proxies</p>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row gap-12 w-11/12 md:w-3/4 mt-12 xl:w-1/2 items-center'>
                    <div className='flex flex-col gap-8 self-center'>
                        <div className='flex flex-col gap-4'>
                            <p className='text-[#B3D1E9] text-md self-start'>Profile groups</p>
                            <p>Easily catergorize profiles for easy access when making tasks</p>
                        </div>
                    </div>
                    <img src={profiles} alt="Arc Profiles" className=' w-11/12 md:w-3/4 xl:w-2/3'></img>
                </div>
                <img src={ui} alt="Arc UI" className=' w-11/12 md:w-3/4 xl:w-1/2'></img>
                <Caption text="Remainder of UI" bg={main} />
                <Paragraph
                    colour={main}
                    title='Visual treatment'
                    paragraph="I knew I wanted the branding to be minimalistic and clean, while still keeping it colourful (relatively). The colour blue was chosen as it is often associated with calmness, reliability and responsibility. Consistency in the colours is used throughout the design." />
                <img src={branding} alt="Arc Branding" className='rounded-lg w-11/12 md:w-3/4 xl:w-1/2'></img>
                <Caption text="Arc Branding" bg={main} />
                <ProjectImage src={promo} alt='Arc Promo' style='w-11/12 md:w-3/4 xl:w-1/2' />
                <Caption text="Promotional posts" bg={main} />
                <PageFlip next='Works' nextLink='/' />
            </div>
        </div>
    );
}

export default HTV;