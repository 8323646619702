import React, { useState } from 'react'
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import PageFlip from '../../components/PageFlip';
import components from '../../assets/images/stegvision/components.png';
import dashboard from '../../assets/images/stegvision/dashboard.png';
import Warning from '../../components/Warning';
import WorkHeader from '../../components/WorkHeader';
import Paragraph from '../../components/Paragraph';
import ProjectImage from '../../components/ProjectImage';


function Stegvision() {

    useEffect(() => {
        document.getElementById("works").className = 'text-secondary line-through'
        window.scrollTo(0, 0);
    }, []);

    const [bg, setBg] = useState('#F6AD71');
    const [accent, setAccent] = useState('#747474');
    const [main, setMain] = useState('#D6A158');


    return (
        <div className='bg-[#fff] pb-20'>
            <div className='flex flex-col w-full overflow-x-hidden items-center'>
                <Helmet>
                    <title>Stephanie Zhang  ⎯  StegVision</title>
                </Helmet>
                <WorkHeader
                    title='StegVision'
                    bg={bg}
                    accent={accent}
                    timeline='January 2021'
                    role='Lead Designer'
                    skills='Product Design, UI/UX, Website'
                />
                <div className='flex flex-col gap-12 items-center my-12'>

                    <img src={dashboard} alt='Steg dashboard' className='w-11/12 md:w-3/4 xl:w-1/2 rounded-lg'></img>
                    <Warning title='This is under construction!' 
                    desc='I would love to share the work I have done for StegVision, but this page is still under construction. If you would like to see the designs up close, contact me!' 
                    link='mailto:stephaniey.zhang@mail.utoronto.ca' 
                    button='email me :-)' 
                    bg={bg}
                    buttoncolour='#fff'/>
                    <PageFlip next='ICUBE UTM' nextLink='/icube' />
                </div>
            </div>
        </div>
    );
}

export default Stegvision;